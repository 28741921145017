/**
 * @file Finding table component
 */
import React from 'react';
import shortid from 'shortid';

/**
 * Findings table component
 *
 * @function
 * @param {Array} findings to be displayed
 * @returns {object} React element
 */
export const MRTable = ({
  findings,
  clickFunction,
  getSequenceNameById,
  getScanPlaneById,
  viewerKey
}) => {
  return (
    <table className='col-md-12 table table-striped is-fullwidth'>
      <thead>
        <tr>
          <th>#</th>
          <th>Finding</th>
          <th>Recommendation</th>
          <th>View Finding</th>
        </tr>
      </thead>
      <tbody id='studyResultData'>
        {findings &&
          findings.length > 0 &&
          findings.map((finding, index) => {
            return (
              <tr key={shortid.generate()}>
                <td>{index + 1}</td>
                <td>{finding.candidateFindingsLabelGroup}</td>
                <td>
                  {finding.vol_ratio ? (
                    <ul>
                      <li>{finding.vol_ratio}</li>
                      <li>{finding.flair_vol}</li>
                      <li>{finding.dwi_vol}</li>
                      <li>{finding.whole_int}</li>
                      <li>{finding.cont_int}</li>
                    </ul>
                  ) : (
                    finding.recommendation
                  )}
                </td>
                <td>
                  <ul>
                    {finding.segmentations.map((segmentation, idx) => {
                      let name = getSequenceNameById(segmentation.seriesId);
                      if (
                        segmentation.niftiSegmentation.subSampled === undefined
                      ) {
                        name = '';
                      }
                      let scanPlane = getScanPlaneById(segmentation.seriesId);
                      let isSelected =
                        viewerKey.seriesId === segmentation.seriesId &&
                        viewerKey.findingIndex === index;
                      return (
                        <li
                          key={shortid.generate()}
                          className={
                            'result-view__view ' + (isSelected ? 'active' : '')
                          }
                        >
                          <span
                            onKeyDown={() => {
                              /* ESLint mock handler */
                            }}
                            onClick={() => {
                              clickFunction(
                                segmentation.seriesId,
                                index,
                                scanPlane,
                                findings
                              );
                            }}
                            role='button'
                            tabIndex='0'
                            data-image={`${segmentation.image}`}
                            data-overlay={`${segmentation.overlay}`}
                          >
                            {name}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
